import React from "react";
import { Link } from "react-router-dom";
import Badge from "../assets/Badge.png";

const Footer = () => {
  return (
    <div className="bg-pale-blue py-7 text-center mt-20 text-white font-medium ">
      <div className=" text-sm mt-5">
        <a href="/" className="ml-5">
          Home
        </a>
        <a href="/" className="ml-10">
          Services
        </a>
        <a href="/aboutus" className="ml-10">
          About Us{" "}
        </a>
        <a href="/contact" className="ml-10">
          Contact
        </a>
      </div>

      <div className="text-2xl mt-8">
        <Link to="/" className="ml-3">
          <ion-icon name="logo-facebook"></ion-icon>
        </Link>
        <Link to="/" className="ml-3">
          {" "}
          <ion-icon name="logo-twitter"></ion-icon>
        </Link>
        <Link to="/" className="ml-2">
          <ion-icon name="logo-linkedin"></ion-icon>
        </Link>
      </div>
      <img
        src={Badge}
        alt="Microsoft for startupss badge"
        className="max-w-xs m-auto mt-8"
      />
      <div className="text-sm mt-6">2024 Techcohort. All rights reserved</div>
    </div>
  );
};

export default Footer;
