// ProductPage.js

import React from "react";
import ProductCard from "../components/ProductCard";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const products = [
  {
    id: 1,
    name: "Olukowe",
    description: "Get your writing smarter and faster with our AI power application that enhance your writing. Our powerful tool uses advanced algorithms and natural language processing to generate high-quality content in seconds, whether you need help with creative writing, copywriting, or any other type of writing, our AI-powered application has got you covered",
    image: "img/olukowe.png",
  },
  
];

const ProductPage = () => {
  return (
    <>
      <Nav />
      <div className="container mx-auto py-8">
        <h1 className="text-4xl font-bold mb-8 uppercase text-center">Our Products</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
