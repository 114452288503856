// ProductCard.js

import React from "react";

const ProductCard = ({ product }) => {
  return (
    <div className="border border-gray-200 rounded-md shadow-md overflow-hidden">
      <div className="aspect-w-3 aspect-h-2">
        <img
          src={product.image}
          alt={product.name}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
        <p className="text-gray-600 mb-4">{product.description}</p>
        <a
          href={product.link}
          className="bg-blue-500 text-white px-5 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 block text-center"
        >
          View Details
        </a>
      </div>
    </div>
  );
};

export default ProductCard;
