import React from "react";
import "./Contact.css";

//component
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import Quote_left from "../../assets/AIcons/Quote-left.png";
import Quote_right from "../../assets/AIcons/Quote-right.png";

const Contact = () => {
  return (
    <div>
      <div className="header">
        <Nav />
        <div className="text-center back py-24 text-3xl text-white font-bold">
          Send Us A Message
        </div>
      </div>
      <section className="bg-contact-bg py-28 m-0 mb-[-160px]">
        <div className="md:grid grid-cols-2 max-w-5xl md:m-auto ml-10 mr-10 ">
          <div>
            <div className="text-2xl font-bold mt-[-50px]">
              Reach out to us today and let's explore how our AI solutions can
              revolutionize your business.
            </div>

            <div className="bg-banner bg-cover pb-2 pt-7 px-8 mt-40 mr-[-30px] rounded-md">
              <img src={Quote_left} alt="icon" className="inline" />
              <div className="text-xs text-white font-light mt-[-20px] ml-7 leading-6 ">
                In the realm of innovation, our AI journey is a testament to
                unwavering determination. Fueled by a vision to enhance lives,
                we persistently push boundaries, crafting a future where
                technology elevates humanity. Each line of code, a promise;
                every breakthrough, a step towards a brighter, more connected
                world. Together, we build a legacy of positive change, one
                algorithm at a time.
                <img src={Quote_right} alt="icon" className="inline" />
              </div>
              <div className="text-white mt-6 ml-7">
                <p className="font-semibold text-sm">Feyi Eniola</p>
                <p className="font-normal text-sm">Co-founder of Techcohort</p>
              </div>
            </div>
          </div>
          <div className="md:ml-28">
            <Form />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
