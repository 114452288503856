import React from "react";

const Button = (props) => {
  return (
    <button
      className="bg-pale-blue text-white  py-3 px-5 rounded-3xl md:ml-8 hover:bg-opacity-60
    duration-500"
    >
      {props.children}
    </button>
  );
};

export default Button;
