import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ea04bge",
        "template_b3dz7go",
        form.current,
        "OkON8PzPS2gfUR7kp"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="container bg-white border-2 rounded-lg px-10 py-8 mt-28 md:mt-[-45px] ">
      <div className="font-bold text-2xl">Send us a message</div>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col text-text-4 mr-4"
      >
        <label className="mt-14 mb-2 text-sm">Your name</label>
        <input type="text" name="user_name" className="border-b-2 " required />
        <label className="mt-8 text-sm">Email address</label>
        <input
          type="email"
          name="user_email"
          className="border-b-2 "
          required
        />
        <label className="mt-6 mb-2 text-sm">Phone number</label>
        <input
          type="number"
          name="user_number"
          required
          className="border-b-2 "
        />
        <label className="mt-8 text-sm ">Write your message</label>
        <textarea
          name="message"
          cols="30"
          rows="4"
          required
          className="border-b-2 "
        />
        <button type="submit" className="bg-pale-blue mt-6 py-1 text-white">
          Send
        </button>
      </form>
    </div>
  );
};

export default Form;
