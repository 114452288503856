import React from "react";
import "./Hero.css";
const Hero = () => {
  return (
    <>
      <div className="bg-hero bg-cover ">
        <div className="ml-10">
          <h1 className="text-5xl text-white font-bold  leading-tight mt-[-80px]  md:text-6xl ">
            Unlock the Future <br /> with AI Solutions
          </h1>
          <div className=" text-xl text-white mt-20 text-pretty  ">
            We build intelligent solutions that tackle <br />
            your business challenges head-on
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
