import React from "react";
import AboutPic from "../assets/images/AboutPic.png";
import Footer from "../components/Footer";
import Nav from "../components/Nav";


const AboutUs = () => {
  return <div>
    <Nav />

    <section>
          <div className=" mb-8 md:grid grid-cols-2 md:max-w-5xl m-auto mt-20">
            <div className="text-center md:text-start">
              <span className="font-semibold text-xl bg-pale-blue text-white p-4 py-2 rounded-full ">
                A
              </span>
              <p className="font-semibold text-2xl mt-5">About US </p>

              <p className="text-text-2 text-balance mt-5 text-sm">
                At Techcohort, we are dedicated to crafting AI solutions that go
                beyond technology - they solve real business problems. Our team
                of experts combines innovation and expertise to deliver
                cutting-edge solutions tailored to your unique needs.
              </p>

              <p className="text-text-2 text-balance mt-5 text-sm">
              At Techcohort, we believe AI is more than just fascinating technology. We're passionate about crafting intelligent solutions that become powerful tools for businesses. Our team isn't just a collection of brilliant minds; it's a synergy of innovation and practical expertise.
              </p>

              <p className="text-text-2 text-balance mt-5 text-sm">
              We don't just follow trends; we delve into the core of your unique challenges to tailor AI solutions that deliver tangible results.
              </p>
              
            </div>
            <div className="md:ml-[-60px] md:mt-0 mt-14  ">
              <img src={AboutPic} alt="people working on a laptop" />
            </div>
          </div>
        </section>

        <Footer />
  </div>;
};

export default AboutUs;
