import React from "react";

// Components
import Nav from "../components/Nav";
import Hero from "../components/Hero/Hero";
import Footer from "../components/Footer";
import Form from "../components/Form";

//images
import AboutPic from "../assets/images/AboutPic.png";
import ServicePic from "../assets/images/ServicePic.png";
import Icon_2 from "../assets/AIcons/Icon_2.png";
import Icon_3 from "../assets/AIcons/Icon_3.png";
import Icon_1 from "../assets/AIcons/Icon_1.png";
import VR from "../assets/images/VRpic.png";
import Quote_left from "../assets/AIcons/Quote-left.png";
import Quote_right from "../assets/AIcons/Quote-right.png";

const Landing = () => {
  return (
    <>
      <div>
        <Nav />
      </div>
      <div>
        <Hero />
      </div>
      <body className=" ml-10 mr-10 md:m-0">
        <section>
          <div className=" md:grid grid-cols-2 md:max-w-5xl m-auto mt-40">
            <div className="text-center md:text-start">
              <span className="font-semibold text-xl bg-pale-blue text-white p-4 py-2 rounded-full ">
                A
              </span>
              <p className="font-semibold text-2xl mt-5">About US </p>
              <p className="text-text-2 text-balance mt-5 text-sm">
                At Techcohort, we are dedicated to crafting AI solutions that go
                beyond technology - they solve real business problems. Our team
                of experts combines innovation and expertise to deliver
                cutting-edge solutions tailored to your unique needs.
              </p>
            </div>
            <div className="md:ml-[-60px] md:mt-0 mt-14  ">
              <img src={AboutPic} alt="people working on a laptop" />
            </div>
          </div>
        </section>

        <section>
          <div className=" md:grid grid-cols-2 max-w-5xl m-auto mt-48 ">
            <div>
              <img
                src={ServicePic}
                alt="People analysing data on a tablet"
                className="md:ml-[-30px] "
              />
            </div>

            <div className="md:grid grid-cols-2 ml-5 ml-0 mr-0 ">
              <div>
                {" "}
                <div className="text-center md:text-start">
                  <span className="text-3xl font-bold relative md:top-[-30px] top-[-360px] ">
                    Services
                  </span>
                  <p className="text-text-3 mt-3 text-sm">
                    We are creating solutions that is changing lives of all
                    community
                  </p>
                </div>
                <div className="mt-20 text-center shadow-lg md:px-10 md:py-2 py-3 px-8 md:mr-[-20px] md:ml-0 ml-10 mr-10">
                  <img src={Icon_2} alt="icon" className="m-auto" />
                  <div>
                    <span className="text-text-3 font-medium mt-3">
                      Custom AI Development
                    </span>
                    <p className="text-text-2 text-xs text-justify mt-3">
                      From concept to deployment, we create bespoke AI solutions
                      to address your specific business challenges.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-10 md:ml-10 md:mr-[-40px] md:mt-[-22px] ml-10 mr-10 ">
                {" "}
                <div>
                  {" "}
                  <div className="text-center shadow-lg md:py-2 py-6 px-8 ">
                    <img src={Icon_1} alt="icon" className="m-auto" />
                    <div>
                      <span className="text-text-3 font-medium mt-3">
                        Data-Driven Insights
                      </span>
                      <p className="text-text-2 text-xs text-justify mt-3">
                        Harness the power of data with our AI solutions that
                        provide actionable insights to drive informed
                        decision-making
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  {" "}
                  <div className=" text-center shadow-lg md:py-2 py-6 px-8 md:mt-5 mt-10">
                    <img src={Icon_3} alt="icon" className="m-auto" />
                    <div>
                      <span className="text-text-3 font-medium mt-3">
                        Process Automation
                      </span>
                      <p className="text-text-2 text-xs text-justify mt-3">
                        Optimize your workflows and enhance efficiency with our
                        AI-driven process automation solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="bg-pale-blue  bg-VR bg-cover md:bg-none w-11/12 m-auto rounded-xl h-96 mb-40">
            <div className="md:grid grid-cols-2 max-w-5xl m-auto mt-52 ">
              <div className="md:mt-14 ml-5 md:ml-0  ">
                <p className="md:text-5xl text-4xl pt-8 md:pt-0 font-semibold leading-normal text-white">
                  Ready to Transform Your Business?
                </p>
                <p className="mt-20 text-md text-white font-medium">
                  Discover how our AI solutions can elevate your operations and
                  solve real business problems.
                </p>
              </div>
              <div>
                <img
                  src={VR}
                  alt="VR"
                  className="ml-20 mt-14 hidden md:block"
                />
              </div>
            </div>
          </div>
        </section>
      </body>
      <section className="bg-contact-bg py-28 m-0">
        <div className="md:grid grid-cols-2 max-w-5xl md:m-auto ml-10 mr-10 ">
          <div>
            <div className="text-2xl font-bold mt-[-50px]">
              Reach out to us today and let's explore how our AI solutions can
              revolutionize your business.
            </div>

            <div className="bg-banner bg-cover pb-2 pt-7 px-8 mt-40 mr-[-30px] rounded-md">
              <img src={Quote_left} alt="icon" className="inline" />
              <div className="text-xs text-white font-light mt-[-20px] ml-7 leading-6 ">
                In the realm of innovation, our AI journey is a testament to
                unwavering determination. Fueled by a vision to enhance lives,
                we persistently push boundaries, crafting a future where
                technology elevates humanity. Each line of code, a promise;
                every breakthrough, a step towards a brighter, more connected
                world. Together, we build a legacy of positive change, one
                algorithm at a time.
                <img src={Quote_right} alt="icon" className="inline" />
              </div>
              <div className="text-white mt-6 ml-7">
                <p className="font-semibold text-sm">Feyi Eniola</p>
                <p className="font-normal text-sm">Co-founder of Techcohort</p>
              </div>
            </div>
          </div>
          <div className="md:ml-28">
            <Form />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Landing;
